<template lang="pug">
    div.flex.horizontal-expand.horizontal-centralize.vertical-centralize()
        div.flex.horizontal-expand.expand-vh-vertical(style="min-height: 100vh; background: linear-gradient(to right, #fff, #fff);; display: flex; width: 100%; justify-content: center;")
          v-flex.pa-3(xs12 md3 v-if="passo===1")
              v-form(ref="form")
                  div.flex.horizontal-expand.horizontal-centralize.vertical-centralize.column
                      div.flex.expande-horizontal
                        v-btn(icon @click="$router.go(-1)" class="elevation-1" color="grey darken-3")
                          v-icon mdi-chevron-left
                      div.flex.expande-horizontal.centraliza
                        img(height="100" src="logo.png" style="padding: 12px; border-radius: 6px;")
                      div.expande-horizontal.centraliza
                        span.margin-vertical.mb-6.fonte.text-center.mt-1.pr-6.pl-6.black--text Faça seu cadastro abaixo.

                      div.flex.horizontal-expand.column
                          v-flex(xs12)
                              span.fonte.black--text Nome Completo
                          v-flex(xs12)
                              v-text-field(
                                  dense
                                  
                                  v-model="payload.name"
                                  placeholder="Digite o seu nome completo"
                                  :rules="[v => !!v || 'Preencha aqui']"
                                  :color="$theme.primary"
                                  solo-inverted
                                  flat
                              )
                      div.flex.horizontal-expand.column
                          v-flex(xs12)
                              span.fonte.black--text E-mail
                          v-flex(xs12)
                              v-text-field(
                                  dense
                                  v-model="payload.email"
                                  placeholder="Informe o seu e-mail"
                                  :rules="[v => !!v || 'Preencha aqui']"
                                  :color="$theme.primary"
                                  solo-inverted
                                  flat
                              )
                      div.flex.horizontal-expand.column
                          v-flex(xs12)
                              span.fonte.black--text CPF
                          v-flex(xs12)
                              v-text-field(
                                  dense
                                  v-model="payload.cpf"
                                  v-mask="'###.###.###-##'"
                                  placeholder="Informe o seu cpf"
                                  :rules="[v => !!v || 'Preencha aqui']"
                                  :color="$theme.primary"
                                  solo-inverted
                                  flat
                              )
                      div.flex.horizontal-expand.column
                          v-flex(xs12)
                              span.fonte.black--text Telefone
                          v-flex(xs12)
                              v-text-field(
                                  dense
                                  v-model="payload.phone"
                                  placeholder="Informe o seu telefone"
                                  :rules="[v => !!v || 'Preencha aqui']"
                                  :color="$theme.primary"
                                  solo-inverted
                                  v-mask="['(##) #####-####', '(##) ####-####']"
                                  flat
                              )
                      
                      div.flex.horizontal-expand.column
                        v-flex(xs12)
                          span.fonte.black--text Senha para login
                        v-flex(xs12)
                          v-text-field(
                              dense
                              v-model="payload.senha"
                              :rules="[v => !!v || 'Preencha este campo']"
                              placeholder="******"
                              type="password"
                              :color="$theme.primary"
                              solo-inverted
                              flat
                          )

                      div.flex.horizontal-expand.column
                        v-flex(xs12)
                            span.fonte.black--text Confirme a Senha para login
                        v-flex(xs12)
                            v-text-field(
                                dense
                                
                                v-model="confirm_senha"
                                :rules="[v => !!v || 'Preencha aqui']"
                                placeholder="******"
                                type="password"
                                :color="$theme.primary"
                                solo-inverted
                                flat
                            )
                      
                      div.flex.horizontal-expand.column
                        v-flex(xs12)
                          span.fonte.red--text {{ erro }}
                          

                      //- div.mb-6.expande-horizontal.centraliza
                      //-   v-switch(class="pa-2"  color="light-green" outlined v-model="termos" label="Ao criar a conta você concorda com os nossos termos de uso.")
                      div.mb-6.expande-horizontal.centraliza
                        v-btn.fonte(
                          :disabled="senhaOk ? false : true"
                          depressed
                          dark
                          rounded
                          :loading="loading"
                          class="fonte"
                          @click="valida_form"
                          :color="$theme.primary"
                        ) 
                          span.fonte Finalizar e Cadastrar
                      
                      div.mb-6.flex.horizontal-expand.column
                        v-btn.fonte(
                            @click="$router.push('/')"
                            text
                            small
                            block
                            
                            :color="$theme.primary"
                        )
                          span.black--text Tem um cadastro? Faça Login

                      //- h3.margin-vertical.mb-12.fonte.text-center Ao criar uma conta, você concorda com a nossa Política de Privacidade.
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import validarCPF from "validar-cpf";

export default {
  props: ["sponsor_id"],
  data() {
    return {
      passo: 1,
      loading: false,
      modal: false,
      termos: true,
      erro: "",
      confirm_senha: "",
      sex_options: [
        {
          name: 'Masculino',
          value: 'M'
        },
        {
          name: 'Feminino',
          value: 'F'
        }
      ],
      ethnicity_options: [
        'Branca', 
        'Não Informado', 
        'Preta', 
        'Parda', 
        'Amarela', 
        'Indígena'
      ],
      payload: {
        transaction_senha: "",
        senha: "",
        sponsor: {
          _id: "nosponsor"
        },
        address: {}
      },
      senhaOk: false
    };
  },
  watch: {
    confirm_senha(val) {
      if (val === this.payload.senha) {
        this.senhaOk = true;
        this.erro = "";
      } else {
        this.senhaOk = false;
        this.erro = "Senhas não correspondem";
      }
    }
  },
  methods: {
    ...mapActions(["createUser", "createGlobalMessage"]),
    cpfIsValid() {
      if (!validarCPF(this.payload.cpf)) {
        this.createGlobalMessage({
          timeout: 6000,
          type: "error",
          message: "CPF inválido"
        });
        return false;
      }
      return true;
    },
    valida_form() {
      this.loading = true;
      if (!this.cpfIsValid()) return;
      if (this.$refs.form.validate()) {
        this.loading = true;
        this.$run("signup-seller", this.payload, "external").then(res => {
          this.loading = false;
          this.createGlobalMessage({
            timeout: 6000,
            type: "success",
            message: "Cadastro realizado com sucesso"
          });
          this.$router.push("/");
        }).catch(e => {
          this.loading = false;
          this.createGlobalMessage({
            timeout: 6000,
            type: "error",
            message: e.response.data.message
          });
        });
      }
    }
  }
};
</script>
